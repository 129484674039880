import { Button, Dialog, DialogContent, DialogDescription, DialogFooter, DialogTitle } from "@fscrypto/ui";
import { Link, useLocation, useNavigate, useSubmit } from "@remix-run/react";
import { useEffect } from "react";
import { $path } from "remix-routes";
import { tracking } from "~/utils/tracking";

export default function MarketingAuthModal(args: {
  persona: "analyst" | "developer" | "viewer" | "earner";
  open: boolean;
  redirectBackOnClose: boolean;
  defaultOpen: boolean;
  onOpenChange?: (open: boolean) => void;
  origin:
    | "dashboard-refresh"
    | "dashboard-parameters"
    | "dashboard-view"
    | "dashboard-like"
    | "query-fork"
    | "nav-studio"
    | "nav-api";
}) {
  const { persona, open, redirectBackOnClose, defaultOpen, origin, onOpenChange } = args;
  const location = useLocation();
  const submit = useSubmit();
  const navigate = useNavigate();

  const baseUrl = typeof window === "undefined" ? process.env.BASE_URL : window.ENV.BASE_URL;
  const redirectUrl = `${baseUrl}${location.pathname}${location.search}${location.hash}`;

  const title: string = (() => {
    switch (persona) {
      case "analyst":
        return "Sign up for free Studio access";
      case "developer":
        return "Sign up for free API access";
      case "viewer":
        return "Sign up to Personalize your Blockchain Insights";
      default:
        return "Sign up";
    }
  })();

  const handleAuthClick = (isRegister: boolean) => {
    const event = isRegister ? "signup" : "login";
    tracking(`auth_modal_${event}_click`, "Homepage", { persona, origin });
    submit(null, {
      method: "post",
      action: $path("/auth/auth0", { screen_hint: isRegister ? "signup" : "", redirectUrl }),
    });
  };

  const handleAuthModalOpenChange = (open: boolean) => {
    if (!open) {
      tracking("auth_modal_close", "Homepage", { persona, origin });
      if (redirectBackOnClose) navigate(-1);
    }
    if (onOpenChange) onOpenChange(open);
  };

  useEffect(() => {
    if (open) {
      tracking("auth_modal_open", "Homepage", { persona, origin });
    }
  }, [open, persona, origin]);

  return (
    <Dialog open={open} defaultOpen={defaultOpen} onOpenChange={handleAuthModalOpenChange}>
      <DialogContent className="w-5/6 md:w-3/5" autoFocus={false}>
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription className="prose">
          {persona === "developer" && (
            <ul>
              <li>
                Programmatic access to the most comprehensive blockchain data in crypto for analysts, developers, and
                data scientists
              </li>
              <li>Access via R, Python, and JS SDKs</li>
              <li>
                Access 500 API query seconds per month and unlimited requests to one JSON endpoint at a time for free
              </li>
              <li>
                Unlock more API seconds and JSON endpoints on all queries with a{" "}
                <Link to={$path("/pricing")} target="_blank">
                  Builder or Pro plan
                </Link>
              </li>
            </ul>
          )}

          {persona === "analyst" && (
            <ul>
              <li>Explore labeled and decoded data on 20+ blockchains and access 60+ third party APIs</li>
              <li>Create unlimited queries and dashboards to showcase your insights</li>
              <li>Gain inspiration from thousands of public queries and dashboards</li>
              <li>Unlimited CSV downloads</li>
              <li>
                Get rewarded for your insights through Flipside's{" "}
                <Link to={$path("/rewards/top-dashboards")} target="_blank">
                  Top Dashboards program
                </Link>
              </li>
            </ul>
          )}

          {persona === "viewer" && (
            <ul>
              <li>Discover and like top dashboards to revisit them with ease</li>
              <li>Quickly apply parameters on dashboards to highlight the data that drives your decisions</li>
              <li>Refresh dashboards to see the latest data</li>
              <li>
                Learn about top protocols and earn with{" "}
                <Link to={$path("/earn")} target="_blank">
                  Flipside Quests
                </Link>
              </li>
            </ul>
          )}

          {persona === "earner" && (
            <ul>
              Sign up to earn crypto
              <li>
                Get rewarded for your insights through Flipside's{" "}
                <Link to={$path("/rewards/top-dashboards")} target="_blank">
                  Top Dashboards program
                </Link>
              </li>
              <li>Complete Quests to earn crypto, learn about top protocols, and increase your onchain footprint</li>
              <li>Track and improve your onchain scores to increase your Quests earning potential</li>
              <li>Manage all your connected wallets and payout addresses</li>
              <li>Claim rewards and track all your earnings per chain</li>
            </ul>
          )}
        </DialogDescription>
        <DialogFooter>
          <Button variant="secondary" size="sm" className="mr-2 px-6" onClick={() => handleAuthClick(false)}>
            Log in
          </Button>
          <Button size="sm" variant="primary" className="px-6" onClick={() => handleAuthClick(true)}>
            Sign up
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
